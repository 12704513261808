import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

const TypingEffect = ({ strings }) => {
    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: strings,
            typeSpeed: 50,
            backSpeed: 50,
            loop: true,
        });

        return () => {
            typed.destroy();
        };
    }, [strings]);

    return (
        <span>
            <span className="typed" ref={el}></span>
            <span className="typed-cursor" aria-hidden="true">|</span>
        </span>
    );
}

export default TypingEffect;
