import React from 'react';
import '../App.css';
import { services } from '../constants'; // Ensure this path is correct
import ButtonLink from './ButtonLink';
import Footer from './Footer';

const About = () => {
  return (
    <div className='flex flex-col min-h-screen bg-black'>
      <div className='flex-1 text-white overflow-x-hidden' id='about'>
        <div className='h-full w-full text-white sm:flex sm:justify-around about py-12 mt-8'>
          <div className='flex flex-col justify-around'>
            <div className='sm:px-16 px-2'>
              <h2 className='text-4xl sm:text-5xl font-extrabold mt-2'>Introduction</h2>
              <p className='mt-3 mb-6 text-[17px] max-w-3xl leading-[30px]'>
                👨‍💻 Hi, I'm Avinash Ranga, a 2024 graduate🎓 with a passion for crafting robust backend systems and captivating mobile applications.
                As a seasoned <a className='text-green-300 hover:text-green-500 duration-300' href='https://www.linkedin.com/in/avinash-ranga/' rel="noreferrer" target="_blank">Data & Software Engineer</a>, I specialize in both Data and Software Engineering🚀.
                <br />
                ✍️ Beyond coding, I love to delve into the intricacies of software development and technology trends, basically surfing the internet 🏄.
                <br />
                🕵️‍♂️ When I'm not busy debugging, I'm either baking cookies to bribe my code into working or dreaming of a world where semicolons are optional. 🍪✨
              </p>

              <ButtonLink
                url='https://drive.google.com/file/d/1I9F2mzdos8UD1oTYqrAf9PdhPJqPM1mK/view?usp=drive_link'
                text='View Resume →'
                padding='p-3'
              />
            </div>
            <div className='mt-20 flex justify-center flex-wrap gap-7'>
              {services.map((service) => (
                <div key={service.title} className='sm:w-[250px] w-full'>
                  <div className='w-full green-pink-gradient p-[1px] rounded-[20px]'>
                    <div className='rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col' style={{ background: '#151030' }}>
                      <img
                        src={service.icon}
                        alt='some_icon'
                        className='w-16 h-16 object-contain'
                      />
                      <h3 className='text-white text-[20px] font-bold text-center'>
                        {service.title}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
