export const titles = [
    "Software Engineer        ",
    "Data Engineer        ",
    "Data Scientist           ",
    "Business Intelligence",
    "Technology Enthusiast    ",
];

export const name = 'Avinash';

export const services = [
    {
        title: "Technology Enthusiast",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Software Engineer",
        icon: 'https://csgeeek.github.io/web.png',
    },
];

export const experiences = [
    {
        'company': 'Massachussets General Hospitals & Harvard Medical School',
        'role': 'Data Science Research Co-op',
        'duration': 'January 2024 - July 2024',
        'logo': 'https://www.massgeneralbrigham.org/etc.clientlibs/aem-mgb/clientlibs/clientlib-site/resources/images/logo-icon-mass-general-brigham.png',
        'points': [
            'Analyzed 3+ years of data consisting of 82 columns and cleaned the dataset to narrow down the analysis in order to predict the disease condition based on the historical data analysis from a distributed system of databases.',
            'Independently execute research tasks in Python, such as performing regression, cluster analysis and random forest algorithms to predict the outcome of discrete to assess importance of the features.',
            'Demonstrate excellent organizational skills by having my bio published on the Dekel Lab website, showcasing my dedication to the research team.',
            'To check the accuracy of the machine learning model, I performed hypothesis tests, including T-tests and chi-square tests, to conclude the statistical analysis of the model\'s accuracy 📈.',
            'A brief mediation analysis is performed using bootstrapping technique to get the significance of mediating factor onto the target feature and presented to the research associates about the analysis insights of experimental hypothesis.'
        ],
        'url': 'https://www.massgeneral.org/psychiatry/research/dekel-lab',
    },
    {
        'company': 'Diebold Nixdorf',
        'role': 'Data & Software Engineer R&D',
        'duration': 'March 2022 - July 2022',
        'logo': 'https://www.dieboldnixdorf.com/-/media/diebold/images/global/logo/dn-color-logo.svg',
        'points': [
            'Resolved 175+ enhancement requests in production by developing complex stored procedures, triggers, and complex SQL queries, improving backend software performance and B2B, B2C application functionality.',
            'Implemented the normalization process in the Redshift database from the streamlined input from S3 triggered by AWS Lambda to increase the efficiency of query processing by 40%, achieving minimal latency.',
            'Track the usage of new developments according to the customer experience and present the statistical report to the stakeholders.',
            'Created IAM roles on AWS to orchestrate least privileged role access to Firehose to increase the data protection for data transformation.',
            'Increased user engagement by 70% through Agile processes, Confluence Dashboards, designed sequential workflows collaborating with cross-functional teams and stakeholders improving feedback in continuous improvement activities.'
        ],
        'url': 'https://www.dieboldnixdorf.com/en-us/',
    },
    {
        'company': 'Financial Software and Systems',
        'role': 'Data & Software Engineer',
        'duration': 'August 2019 - March 2022',
        'logo': 'https://www.fsstech.com/sites/default/files/2021-02/Fss30yrnew.png',
        'points': [
            'Developed 20+ ad-hoc dashboards/reports using complex SQL queries (CTEs, joins, window functions) to enhance business intelligence, experimental analytics, query performance, and support data-driven decision making through cloud architecture.',
            'Built custom analytics reports using Power BI to provide insights of product performance and collaborated with stakeholders leading to a 15% improvement in bug fixes in streaming ETL pipeline operations using Spark sourcing data migration.',
            'Automated manual processes by developing a custom VBA application to query data warehouse through ODBC drivers, extract data, and generate PDFs for each record in the returned dataset, thereby resulting in a 60% reduction in execution time.',
            'Maximized the potential of Bitbucket repository, providing seamless version control of code that facilitated team collaboration.',
            'Optimized the queries using Oracle SQL Developer to achieve 30+ million transactions per second on OLTP servers.'
        ],
        'url': 'https://www.fsstech.com/',
    },
];

export const EMAIL_JS_SERVICE_ID = 'service_lc2e55l';
export const EMAIL_JS_TEMPLATE_ID = 'template_1ejzjtb';
export const EMAIL_JS_PUBLIC_KEY = 'sg0wGYUCDDirkeLAu';