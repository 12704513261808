import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID, EMAIL_JS_PUBLIC_KEY } from "../constants";
import Footer from "./Footer";

const Contact = () => {
    const formRef = useRef();
    const [form, setForm] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { target } = e;
        const { name, value } = target;

        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const username = form.name.trim();
        const user_email = form.email.trim();
        const user_message = form.message.trim();

        if (username === '' || user_email === '' || user_message === '') {
            setLoading(false);
            toast.error("Please fill all the fields.", {
                position: 'bottom-right',
            });
            return;
        }

        emailjs
            .send(
                EMAIL_JS_SERVICE_ID,
                EMAIL_JS_TEMPLATE_ID,
                {
                    from_name: username,
                    to_name: "Avinash Ranga",
                    reply_to: user_email,
                    to_email: "coolcracker805@gmail.com",
                    message: user_message,
                },
                EMAIL_JS_PUBLIC_KEY
            )
            .then(
                () => {
                    setLoading(false);
                    toast.success("Message sent successfully!", {
                        position: 'bottom-right',
                    });
                    setForm({
                        name: "",
                        email: "",
                        message: "",
                    });
                },
                (error) => {
                    setLoading(false);
                    console.error(error);
                    toast.error("Uh, oh! Something went wrong. Please try again later.", {
                        position: 'bottom-right',
                    });
                }
            );
    };

    return (
        <div className='flex flex-col min-h-screen bg-black'>
            <div className='flex-1 flex items-center justify-center text-white pt-20 sm:pt-28'>
                <div className='relative w-full sm:w-[650px] p-6 sm:p-8 rounded-2xl bg-gray-900 z-10'>
                    {/* Gradient Border */}
                    <div className='absolute inset-0 -z-10 rounded-2xl border-4 border-transparent bg-clip-padding border-gradient' />
                    <div className='relative z-10'>
                        <div className='text-center mb-8'>
                            <p className='text-lg font-light text-gray-400 mb-2'>REACH OUT TO ME</p>
                            <h2 className='text-4xl sm:text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 via-pink-500 to-purple-600'>
                                Contact.
                            </h2>
                        </div>
                        <form
                            ref={formRef}
                            onSubmit={handleSubmit}
                            className='flex flex-col gap-8'
                        >
                            <label className='flex flex-col'>
                                <span className='font-medium mb-4 text-gray-300'>Your Name</span>
                                <input
                                    type='text'
                                    name='name'
                                    value={form.name}
                                    onChange={handleChange}
                                    placeholder="Enter your name"
                                    className='py-4 px-6 rounded-lg outline-none border-none font-medium bg-gray-800 text-white'
                                    required
                                />
                            </label>
                            <label className='flex flex-col'>
                                <span className='font-medium mb-4 text-gray-300'>Your Email</span>
                                <input
                                    type='email'
                                    name='email'
                                    value={form.email}
                                    onChange={handleChange}
                                    placeholder="Ex:abc@gmail.com"
                                    className='py-4 px-6 rounded-lg font-medium bg-gray-800 text-white'
                                    required
                                />
                            </label>
                            <label className='flex flex-col'>
                                <span className='font-medium mb-4 text-gray-300'>Your Message</span>
                                <textarea
                                    rows={7}
                                    name='message'
                                    value={form.message}
                                    onChange={handleChange}
                                    placeholder='Do you have anything to say?'
                                    className='py-4 px-6 rounded-lg outline-none border-none font-medium bg-gray-800 text-white'
                                    required
                                />
                            </label>

                            <button
                                type='submit'
                                className='py-3 px-8 rounded-xl outline-none mx-auto font-bold shadow-md bg-gray-700 text-white hover:bg-gray-600'
                            >
                                {loading ? "Sending..." : "Send"}
                            </button>
                        </form>
                    </div>
                </div>
                <ToastContainer />
            </div>
            <Footer />
        </div>
    );
};

export default Contact;
